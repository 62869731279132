<template>
    <v-row class="mt-10">
        <v-col cols="12" class="d-flex flex-column align-center px-10">
            <h4 class="logos-title text-center">Colaboramos con las principales entidades financieras de España</h4>        
            <div class="container-slider">
                <!-- <div class="photobanner">                        
                    <img  v-for="(logo,i) in logos" :key="i" :class="{'first': i == 0}" :src="logo.url" >                    
                </div> -->
                <VueSlickCarousel v-bind="slickSettings">
                    <div v-for="(logo,i) in logos" :key="i"><img :class="{'first': i == 0}" :src="getImgSrc(logo)" ></div>
                </VueSlickCarousel>
            </div>
        </v-col>

    </v-row>
    
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'

    import {mapState} from 'vuex';

    export default {
        components:{
            VueSlickCarousel
        },
        props:['imgType'],
        data() {
            return {
                slickSettings:{
                    "arrows": false,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 6,
                    "slidesToScroll": 1,
                    "autoplaySpeed": 2000,
                    "autoplay": true,
                    "speed": 1000,
                    // "lazyLoad": 'ondemand',
                    "cssEase": "ease-in-out",
                    responsive: [
                        {
                            breakpoint: 1500,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 4
                            }
                        }
                    ]
                }
            }
        },
        computed: {
            ...mapState([
                'logos'
            ]),
            getImgSrc(){
               return (a) => {
                   if (!this.imgType){
                       return a.url
                   }                   
                   return this.imgType == 'neg' ? a.url_neg : a.url
                }
                
            }
        },
        mounted() {},
    }
</script>


<style lang="scss">
.slick-slide img{
    width: 100%;
}
</style>
<style lang="scss" scoped>
    .logos-title{
        font-family: 'montserrat';
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #333;
    }
@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

/* Photo Banner */
.container-slider {
    width: 60%;
    height: auto;
    overflow: hidden;
    margin: 15px auto;
    background: white;
    @media (max-width: 960px) {
         width: 90%;
    }
}

</style>