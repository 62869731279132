<template>
    <footer>
        <div class="row">
            <div class="text-center text-white" :class="brokerData.portal_data.logo_bottom_url ? 'col-sm-12 col-md-6' : 'col'">
                <div class="row text-center">
                    <div class="col">
                        <img src="https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/logos/helloteca/LogoHelloteca2.svg" class="image attachment-full size-full helloteca_logo_footer logo_footer">
                    
                        <a class="text-white small-footer mr-4"
                        href="https://helloteca.com/politica-privacidad"
                        target="_blank">
                            Política de privacidad
                        </a>
                        <a class="text-white small-footer " href="https://helloteca.com/terminos-y-condiciones" target=" _blank">
                            Términos y condiciones
                        </a>
                    </div>
                </div> 
            </div>
            <div class="col-sm-12 col-md-6 text-center" v-if="brokerData && brokerData.portal_data.logo_bottom_url">
                <div class="row">
                    
                    <div class="col">
                        <div>
                            <img :src="brokerImg"
                                class="image attachment-full size-full logo_footer">
                            <a class="terms-broker small-footer mr-4"
                                :href="brokerData.portal_data.privacy_url"
                                target="_blank">
                                    Política de privacidad
                            </a>
                            <a class="terms-broker small-footer " :href="brokerData.portal_data.terms_url" target=" _blank">
                                Términos y condiciones
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

    </footer>
</template>
<script>
    import {mapState} from 'vuex';
    export default {
        data(){
            return{}
        },
        mounted(){
        },
        methods: {
            isValidURL(string) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(string);
            },
        },
        computed: {
            ...mapState([
                'brokerData'
            ]),
            brokerImg(){
                try {
                    if(this.isValidURL(this.brokerData.portal_data.logo_bottom_url)){
                        return this.brokerData.portal_data.logo_bottom_url
                    } else{
                        return ''
                    }
                    //return this.isValidURL(this.brokerData.portal_data.logo_top_url) ? this.brokerData.portal_data.logo_top_url : 'https://helloteca-app.web.app/img/logo.27ca1e98.svg';
                } catch (error) {
                    return '';
                }
            }
        }        
    }
</script>
<style lang="scss" scoped>
    footer {
        .terms-broker{
            color: var(--helloteca-main);
        }
        bottom: 0px;
        width: 100%;
        background-color: #222;
        padding-top: 2%;
        padding-bottom: 2%;
        text-align: center;
        position: relative;
        .row {
            max-width: 1080px;
            margin: auto;
            .image {
                height: 80px;
                width: 100%;
                object-fit: contain;
                @media (max-width: 768px) {
                    max-width: 50%;
                    height:auto;
                }
            }
        }
        a{
            color: #22CCD5;
        }
        .helloteca_logo_footer{
            @media (max-width: 768px) {
                width: 150px;
                height:auto;
                display: block;
                margin: auto;
            }
        }
    }
</style>