<template>
    <v-app>
        <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">
    
        <Header v-if="headerVisible && !showIframe"/>
        <router-view v-if="initalDataLoaded"></router-view>
        <FooterLogos v-if="initalDataLoaded && activeStep != 4  && !isSolvia  && !showIframe"/>
        <Footer v-if="initalDataLoaded && !isSolvia && !showIframe"/>
        <FooterHelloteca v-if="initalDataLoaded && isSolvia && !showIframe"/>

        <!-- Loading Data-->
        <template v-if="!initalDataLoaded || loading">
            <div style="padding: 30px" class="loader_mask d-flex flex-column justify-center align-center">
                <v-progress-circular color="rgba(255,255,255,1)" indeterminate size="50"></v-progress-circular>
            </div>
        </template>
    </v-app>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Header from './components/Header';
    import Footer from './components/Footer';
    import FooterHelloteca from './components/FooterHelloteca';
    import FooterLogos from './components/FooterLogos';

    export default {
        name: 'App',
        data() {
            return {
                initalAccess: true
            }
        },
        components:{
            Header,
            Footer,
            FooterHelloteca,
            FooterLogos
        },
        mounted() {
            // Get common data
            this.getCommonData();
            //this.SET_INITIAL_DATA_LOADED(true);
        },
        computed: {
            ...mapState([
                'initalDataLoaded',
                'loading',
                'activeStep',
                'preScoring',
                'brokerData',
                'showIframe'
            ]),
            isSolvia(){
                return this.$route.params.broker == 'solvia' ? true : false;
            },
            headerVisible(){
                if(!this.initalDataLoaded) return false
                return (!this.isSolvia || this.isSolvia && String(this.$router.currentRoute.name).toLowerCase() != 'solvia')
            }
        },
        methods: {
            ...mapActions([
                'retrieveVariable',
                'getPrescoring',
                'prescoringInit',
                'getBroker',
                'getOffice',
                'brokerInit'
            ]),
            ...mapMutations([
                'SET_BROKER_DATA',
                'SET_TOKEN_ID',
                'SET_LOADING',
                'SET_ACTIVE_STEP',
                'SET_INITIAL_DATA_LOADED',
                'SET_PAGE_ERROR',
                'SET_SHOW_IFRAME',
                'SET_ACTION_STAFF'
            ]),
            initProcessBroker(){
                const paramBroker = this.$route.params.broker||null;             
                this.SET_LOADING(true);

                //IFRAME
                if(this.$route.query.iframe && this.$route.query.iframe == 'helloteca-partners'){
                    this.SET_SHOW_IFRAME(this.$route.query.iframe)
                }
                //Action staff
                if(this.$route.query.action_staff){
                    this.SET_ACTION_STAFF(this.$route.query.action_staff)
                }

                // Get Broker data
                this.getBroker(paramBroker)
                    .then(async response =>{
                        // Set broker data
                        this.SET_BROKER_DATA(response);

                        // Set primary color from response or default
                        let root = document.documentElement;
                        root.style.setProperty('--helloteca-main', response.portal_data.theme_color ? response.portal_data.theme_color : '#25CCD5');
                        //Check token_id from URL param
                        if(this.$route.query.token_id){
                            let prescoringDataFromTokenId = await this.getPrescoring(this.$route.query.token_id)
                                .then(async response=>{
                                    // If url param token_id exist
                                    this.SET_TOKEN_ID(this.$route.query.token_id)
                                    // Save id in browser cookie
                                    this.$cookies.set('ds_noauth_token', this.$route.query.token_id);
                                    let prescoringDataFromCookie = await this.getPrescoring(this.$cookies.get('ds_noauth_token'))
                                        .then(response=>{     
                                                if(!this.$route.params.office){this.$route.params.office='default'}  
                                                if(this.$route.name != 'Success' && this.$route.name != 'View3' && this.$route.name != 'View2'&& this.$route.name != 'View1') {           
                                                    if(this.preScoring.LeadType == 'L1' ){
                                                        this.$router.push({name: 'View2',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                    }else if(this.preScoring.LeadType == 'L2'){
                                                        this.$router.push({name: 'View3',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                        }else if(this.preScoring.LeadType == 'L3'){
                                                        this.$router.push({name: 'Success',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                    }
                                                }
                                                return response.data.data;
                                            })
                                        .catch(async response=>{
                                            // Else check cookies
                                            console.log('ERROR, no se ha podido cargar los datos.');
                                            this.$cookies.remove('ds_noauth_token');
                                            await this.callInitBroker();
                                        })
                                }).catch(async error=>{
                                    let newToken = await this.callInitBroker();
                                    // Update url token_id param
                                    this.$router.push({ query: { token_id: newToken}})
                                })
                        }
                        // Check token_id from cookie
                        else{
                            if(this.$cookies.get('ds_noauth_token')){
                                // If cookie exist, set cookie in state data
                                this.SET_TOKEN_ID(this.$cookies.get('ds_noauth_token'));

                                // and get prescoring data
                                let prescoringDataFromCookie = await this.getPrescoring(this.$cookies.get('ds_noauth_token'))
                                        .then(response=>{     
                                                if(!this.$route.params.office){this.$route.params.office='default'}  
                                                if(this.$route.name != 'Success' && this.$route.name != 'View3' && this.$route.name != 'View2'&& this.$route.name != 'View1') {           
                                                    if(this.preScoring.LeadType == 'L1' ){
                                                        this.$router.push({name: 'View2',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                    }else if(this.preScoring.LeadType == 'L2'){
                                                        this.$router.push({name: 'View3',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                        }else if(this.preScoring.LeadType == 'L3'){
                                                        this.$router.push({name: 'Success',params: { broker: this.$route.params.broker,office:this.$route.params.office} });
                                                    }
                                                }
                                                    
                                                return response.data.data;
                                            })
                                        .catch(async response=>{
                                            // Else check cookies
                                            console.log('ERROR, no se ha podido cargar los datos.');
                                            this.$cookies.remove('ds_noauth_token');
                                            await this.callInitBroker();
                                        })
                            }else{
                                await this.callInitBroker();
                            }
                        }

                        this.SET_LOADING(false);
                        

                        let id_office= this.$route.params.office || 'default';
                        console.log(this.$route);
                        if(this.$route.name != 'View2' && this.$route.name != 'View3' && this.$route.name != 'Success' ){
                            switch (this.brokerData.template) {
                                case 'standard':
                                    if(this.brokerData.portal_data.code =='solvia'){
                                        if(this.$route.name != 'Solvia'){
                                            this.$router.push({name: 'Solvia',params: { broker: this.brokerData.portal_data.code,office:id_office } });
                                        }
                                    }else{
                                        if(this.$route.name != 'View1'){
                                            this.$router.push({name: 'View1',params: { broker: this.brokerData.portal_data.code,office:id_office } });
                                        } 
                                    }
                                    break;
                                case 'solvia':
                                    if(this.$route.name != 'Solvia'){
                                        this.$router.push({name: 'Solvia',params: { broker: this.brokerData.portal_data.code,office:id_office } });
                                    }
                                    break;
                            
                                default:
                                    if(this.$route.name != 'View1'){
                                        this.$router.push({name: '404' });
                                    }                                
                                    break;
                                }
                            }
                            }
                        
                    )
                    .catch((reason) => {
                        // eslint-disable-next-line no-undef
                        console.log('error: ' , reason);
                        this.SET_PAGE_ERROR(true);
                    });

            },
            callInitBroker(){
                let id_office='';
                if(this.$route.params.office && this.$route.params.office !='default'){
                    id_office = this.brokerData.offices.filter(item => item == this.$route.params.office)[0]||'';
                    if(id_office!=''){
                        let data={'broker' : this.brokerData.portal_data.code,'office':id_office}
                        this.getOffice(data).then(async response =>{
                            this.SET_BROKER_DATA(response);
                            let root = document.documentElement;
                            root.style.setProperty('--helloteca-main', response.portal_data.theme_color ? response.portal_data.theme_color : '#25CCD5');
                        }).catch((reason) => {
                        // eslint-disable-next-line no-undef
                        console.log('error: ' , reason);
                        this.SET_PAGE_ERROR(true);
                    });
                    }
                }

                let utm_params = {
                    origen: this.brokerData.portal_data.code,
                    subsource: id_office
                };
                if(this.$route.query.utm_campaign)
                    utm_params = {...utm_params, utm_campaign: this.$route.query.utm_campaign}

                if(this.$route.query.utm_content)
                    utm_params = {...utm_params, utm_content: this.$route.query.utm_content}

                if(this.$route.query.utm_medium)
                    utm_params = {...utm_params, utm_medium: this.$route.query.utm_medium}

                if(this.$route.query.utm_source)
                    utm_params = {...utm_params, utm_source: this.$route.query.utm_source}

                if(this.$route.query.utm_term)
                    utm_params = {...utm_params, utm_term: this.$route.query.utm_term}

                // Init broker and get token_id  
                return new Promise((resolve,reject)=>{
                    this.brokerInit(utm_params)
                        .then(response=>{
                            // Save cookie in client browsers
                            //this.$cookies.set('ds_noauth_token', response);
    
                            resolve(response);
                        })
                })                  
            },

            getCommonData(){
                this.SET_LOADING(true);
                Promise.all([
                    this.retrieveVariable({type:'ccaa'}),
                    this.retrieveVariable({type:'bancos'}),
                    this.retrieveVariable({type:'tipo_contrato'})
                ])
                // eslint-disable-next-line no-unused-vars
                .then(response =>{
                    //this.SET_LOADING(false);
                })
                .catch((reason) => {
                    // eslint-disable-next-line no-undef
                    console.log('error: ' , reason);
                });
            }
        },
        // On access route, check user id form query param or get data
        watch: {
            '$route' (to, from) {                
                if(this.initalAccess){
                    this.initalAccess = false;
                    this.initProcessBroker();
                }                
            }
        },
    };
</script>

<style lang="scss">
    // Global styles
    :root {
        --helloteca-main: #25CCD5;
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
    // @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

    .v-application{
        font-family: 'montserrat' !important;
    }
    .text-white{
        color: #FFF !important;
    }
    $font: 'montserrat' !important;
    // $font2: 'Fredoka One', cursive !important;
    body {
        font-family: $font;
        font-size: 15px;
        font-weight: 500;
        h1 {
            font-size: 32px;
            color: #4C5557;
        }
        h2 {
            font-size: 26px;
        }
        h3 {
            font-size: 22px;
            color: #515151;
            padding-right: 5rem;
            padding-left: 5rem;
        }
        h4 {
            font-size: 20px;

        }
        h5 {
            font-size: 18px;
        }
        p {
            font-size: 20px;
        }
        .text-small {
            font-size: 15px !important;
        }
        .small-footer{
            font-size: 12px!important;
            text-decoration: none;
            
        }
        .text-big {
            font-size: 24px;
        }
        .text-medium {
            font-size: 18px;
        }
        button {
            font-size: 26px;
        }
        label {
            font-size: 18px;
        }
    }
    .v-stepper{
        border-radius: 0 !important;
    }

    .loader_mask{
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(#000, .5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            color: #FFF;
            font-size: 1rem;
            margin-bottom: 15px;
        }
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
        color: rgba(#000, .15) !important;
    }
    .blue_button{
        font-family: 'montserrat' !important;
        letter-spacing: initial;
        border-radius: 6px;
        font-size: 1rem !important;
        line-height: 1.2rem;
        background-color: var(--helloteca-main) !important;
        padding: 12px 60px !important;
        color: #FFF !important;
        font-weight: 600;
        box-shadow: none !important;
        text-transform: none;
        transition: all .2s;
        max-height: initial;
        height: auto !important;
        &:hover{
            background: #2FA8AF !important;
            color:#FFF !important;
            transition: all .2s;
        }
    }

    // Animations
    .fadeR-enter-active {
        animation: slide-in-right 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both;
    }
    .fadeR-leave-active /* .fade-leave-active below version 2.1.8 */ {
        /* animation: slide-out-right 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both; */
        opacity: 0 !important;
        /* display: none; */
        /* position: absolute !important; */
    }
    .fadeL-enter-active {
        animation: slide-in-left 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both;
    }
    .fadeL-leave-active /* .fade-leave-active below version 2.1.8 */ {
        /* animation: slide-out-right 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) both; */
        opacity: 0 !important;
        /* display: none; */
        /* position: absolute !important; */
    }
    @keyframes slide-in-right {
        0% {
            transform: translateX(100px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-left {
        0% {
            transform: translateX(-100px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-out-right {
        0% {
            transform: translateX(0px);
            opacity: 1;
        }
        100% {
            transform: translateX(100px);
            opacity: 0;
        }
    }
</style>
