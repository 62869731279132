import Vue from 'vue'
import VueRouter from 'vue-router'
// import {mapState, mapActions} from 'vuex';
import store from '../store'

Vue.use(VueRouter);



const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/HomeDispatcher.vue')
  },
  {
    path: '/:broker',
    name: 'Broker',
    component: () => import('../views/HomeDispatcher.vue')
  },
  {
    path: '/:broker/view1',
    name: 'View1',
    component: () => import('../views/View1.vue')
  },
  {
    path: '/:broker/view2',
    name: 'View2',
    props: true,
    component: () => import('../views/View2.vue')
  },
  {
    path: '/:broker/view3',
    name: 'View3',
    component: () => import('../views/View3.vue')
  },
  {
    path: '/:broker/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/:broker/:office',
    name: 'BrokerOffice',
    component: () => import('../views/HomeDispatcher.vue')
  },
  {
    path: '/:broker/:office/view1',
    name: 'View1',
    component: () => import('../views/View1.vue')
  },
  {
    path: '/:broker/:office/view2',
    name: 'View2',
    props: true,
    component: () => import('../views/View2.vue')
  },
  {
    path: '/:broker/:office/view3',
    name: 'View3',
    component: () => import('../views/View3.vue')
  },
  {
    path: '/:broker/:office/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/solvia/:office/',
    name: 'Solvia',
    component: () => import('../views/SolviaTemplate.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  // {
  //   path: '*',
  //   name: '404',
  //   redirect: '/view1'
  // },
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
  let url = to.hash;
  let match = ['#!/'];
  if(match.filter(f => url.includes(f)).length > 0){
    url = url.replace(match.filter(f => url.includes(f))[0], '');
    router.push({ path: url }) 
  }
  next();
})

export default router
