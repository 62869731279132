import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import moment from 'moment'

Vue.use(Vuex)

const api = 'https://api7.cloudframework.io/helloteca/score';
const newApi = 'https://api.helloteca.com';
const newApiPRE = 'https://api-dev-dot-helloteca-com.ew.r.appspot.com';
const xWebKey = 'prod_public_3eTH95nz7urVNz8ADAInP5PeC3dv_nEfCHayPY5/4ZrUVMvmrw0VBR7bb';
// const xDataEnv= 'Stage';
const xDataEnv= '';

export default new Vuex.Store({
    state: {
        brokerData: null,
        showIframe:false,
        action_staff:'',
        token_id: null,
        activeStep: 0,
        initalDataLoaded: false,
        freezeState: null,
        preScoring: {
            Titulares: {
                primer_titular:{
                }
            },
            Vivienda:{
                tipo_vivienda:'habitual',
                date_keys:null,
                avalista:null,
                prestamo_personal: 1,
                tipo_prestamo:''
            }
        },
        owners:[{}],
        products:[
            {
                type: 'habitual',
                title: 'Compraventa vivienda habitual',
                button: 'Compra vivienda habitual',
                // icon:'mdi-home-city-outline',
                // help:'Descripción producto vivienda habitual'
            },
            {
                type: 'compra_vivienda_100',
                title: 'Compra vivienda al 100%',
                button: 'Compra vivienda 100%',
                // icon:'mdi-home-city-outline'
            },
            {
                type: 'cambio_vivienda',
                title: 'Cambio de vivienda',
                button: 'Cambio de vivienda',
                // icon:'mdi-home-city-outline'
            },
            {
                type: '2avivienda',
                title: 'Compraventa segunda residencia',
                button: 'Compra 2a vivienda'
            },
            {
                type: 'liquidez',
                title: 'Hipoteca liquidez',
                button: 'Hipoteca liquidez'
            },
            {
                type: 'autopromocion',
                title: 'Autopromoción',
                button: 'Autopromoción'
            },
            {
                type: 'mejorar',
                title: 'Mejora de Hipoteca',
                button: 'Mejora hipoteca actual'
            },
            {
                type: 'ampliacion',
                title: 'Ampliación de hipoteca',
                button: 'Ampliación de hipoteca'
            },
            {
                type: 'extincion_condominio',
                title: 'Extinción de condominio',
                button: 'Extinción de condominio'
            },
        ],
        guarantors:[
            {
                value: 'padres',
                text: 'Padres'
            },
            {
                value: 'hermanos',
                text: 'Hermanos'
            },
            {
                value: 'tios',
                text: 'Tios'
            },
            {
                value: 'pareja',
                text: 'Pareja'
            },
            {
                value: 'propio',
                text: 'Vivienda propia'
            },
            {
                value: 'otros',
                text: 'Otros'
            }
        ],
        propertyStatuses:[
            {
                value: 'buscando',
                text: 'Aún no he comenzado a buscar vivienda'
            },
            {
                value: 'comprar',
                text: 'Aún no tengo vivienda pero estoy buscando'
            },
            {
                value: 'escogida',
                text: 'Tengo vivienda escogida'
            },
            {
                value: 'arras',
                text: 'Ya tengo reserva-arras'
            }
        ],
        propertyNew:[
            {
                value: 1,
                text: 'Obra nueva'
            },
            {
                value: 0,
                text: 'Segunda mano'
            }
        ],
        propertyCurrentMortgage: [
            {
                value: 1,
                text: 'Sí'
            },
            {
                value: 0,
                text: 'No'
            }
        ],
        years:[
            {
                value: '10',
                text: '10'
            },
            {
                value: '15',
                text: '15'
            },
            {
                value: '20',
                text: '20'
            },
            {
                value: '25',
                text: '25'
            },
            {
                value: '30',
                text: '30'
            },
            {
                value: 'indeciso',
                text: 'Aún no lo sé'
            },
        ],
        offerType:[
            {
                value: 'fijo',
                text: 'Fijo'
            },
            {
                value: 'variable',
                text: 'Variable'
            },
            {
                value: 'mixto',
                text: 'Mixto'
            },
            {
                value: 'indeciso',
                text: 'Aún no lo sé'
            }
        ],
        logos:[
            {
                name: 'myinvestor',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/myinvestor-pos.png',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/myinvestor-pos.png',
            },
            {
                name: 'abanca',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/abanca-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/abanca-pos.svg',
            },
            {
                name: 'caixabank',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/caixabank_neg.png',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/caixabank2_pos.png',
            },
            {
                name: 'caja-rural-granada',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/caja-rural-granada_neg.png',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/png/cajagranada3_pos.png',
            },
            {
                name: 'deutsche',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/deutsche-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/deutsche-pos.svg',
            },
            {
                name: 'euroCaja',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/eurocaja-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/eurocaja-pos.svg',
            },
            {
                name: 'kutxa',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/kutxabank-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/kutxabank-pos.svg',
            },  
            {
                name: 'sabadell',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/sabadell-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/sabadell-pos.svg',
            },
            {
                name: 'Santander',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/santander-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/santander-pos.svg',
            },
            {
                name: 'unicaja',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/unicaja-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/unicaja-pos.svg',
            },
            {
                name: 'uci',
                url_neg: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/uci-neg.svg',
                url: 'https://storage.googleapis.com/helloteca-com.appspot.com/helloteca-public/banks-logos/svg/uci-pos.svg',
            }
        ],
        loading: true,
        commonVars:{
            ccaa: []
        },
        terms:false,
        sucessTerms: false,
        accesibleViews: [],
        stepWithError: false,
        pageError: false,
        callsHeader:{'X-WEB-KEY': xWebKey, 'X-DATA-ENV':xDataEnv}
    },
    mutations: {
        SET_BROKER_DATA(state,value){
            state.brokerData = value;
        },
        SET_LOADING(state,value){
            state.loading = value;
        },
        SET_STEP_WITH_ERROR(state,value){
            state.stepWithError = value;
        },
        SET_TERMS(state,value){
            state.terms = value;
        },
        SET_SUCCESS_TERMS(state,value){
            state.sucessTerms = value;
        },
        SET_TOKEN_ID(state,value){
            state.token_id = value;
        },
        SET_INITIAL_DATA_LOADED(state,value){
            state.initalDataLoaded = value;
        },
        SET_ACTIVE_STEP(state,value){
            state.activeStep = value;
        },
        SET_ACTIVE_AVAL(state,value){
            state.preScoring.Vivienda.avalista = value;
        },
        SET_ACTIVE_PERSONAL_LOAN(state,value){
            state.preScoring.Vivienda.prestamo_personal = value;
        },
        SET_PRESCORING_DATA(state,value){
            if(value.Vivienda.tipo_vivienda === null)
                value.Vivienda.tipo_vivienda = 'habitual';

            value.Vivienda.buscando = (value.Vivienda.buscando === 'unknown' || value.Vivienda.buscando === 'propia') ? '' : value.Vivienda.buscando;
            state.preScoring = value;

            let accesibles = null;
            switch (state.preScoring.LeadType) {
                case 'PI':
                    accesibles = ['view1']
                    break;
                case 'L1':
                    accesibles = ['view1','view2']
                    break;
                case 'L2':
                    accesibles = ['view1','view2','view3']
                    break;
                case 'L3':
                    accesibles = ['view1','view2','view3','success']
                    break;
            
                default:
                    accesibles = ['view1']
                    break;
            }
            state.accesibleViews = accesibles;


            state.terms = !!state.preScoring.Titulares.primer_titular.email;

            // Set owners
            if(state.preScoring.Titulares.primer_titular){
                state.owners[0] = state.preScoring.Titulares.primer_titular;
            }
            if(state.preScoring.Titulares.segundo_titular){
                state.owners[1] = state.preScoring.Titulares.segundo_titular;
            }
            if(state.preScoring.Titulares.tercer_titular){
                state.owners[2] = state.preScoring.Titulares.tercer_titular;
            }
            if(state.preScoring.Titulares.cuarto_titular){
                state.owners[3] = state.preScoring.Titulares.cuarto_titular;
            }
        },
        UPDATE_PRESCORING_DATA(state,value){
            state.preScoring = value;
        },
        SET_VALUE(state,value){
            state.preScoring.Vivienda.valor = value;
        },
        SET_DATE_KEYS(state,value){
            state.preScoring.Vivienda.date_keys = value.date;
        },
        SET_CCAA(state,value){
            state.commonVars.ccaa = value;
        },
        SET_BANKS(state,value){
            state.commonVars.banks = value;
        },
        SET_CONTRACT_TYPE(state,value){
            state.commonVars.contractTypes = value;
        },
        CHANGE_ACTIVE_PRODUCT(state, { type, value }) {
            if (type === 'hipoteca') {
                state.preScoring.Vivienda.tipo_vivienda = value;
                state.preScoring.Vivienda.tipo_prestamo = '';
            } else if (type === 'prestamo') {
                state.preScoring.Vivienda.tipo_prestamo = value;
                state.preScoring.Vivienda.tipo_vivienda = '';
            }
        },
        ADD_OWNER(state){
            if(state.owners.length<4)
                state.owners.push({});
        },
        SET_OWNER_BIRTHDATE(state, value){
            state.owners[value.owner].nacimiento = value.date;
        },
        SET_OWNER_JOB_SENIORITY(state, value){
            state.owners[value.owner].antiguedad = value.date;
        },
        DELETE_OWNER(state, value){
            state.owners.splice(value,1);
        },
        FREEZE_STATE(state){
            state.freezeState = JSON.parse(JSON.stringify(state.preScoring));
        },
        BACK_TO_FREEZE_STATE(state){
            state.preScoring = state.freezeState;
            state.freezeState = null;
        },
        SET_PAGE_ERROR(state,value){
            state.pageError = value;
        },
        SET_SHOW_IFRAME(state,value){
            state.showIframe = value;
        },
        SET_ACTION_STAFF(state,value){
            state.action_staff = value;
        }
    },
    actions: {
        // eslint-disable-next-line no-undef,no-unused-vars
        getBroker({state},broker){
            return new Promise((resolve, reject)=>{
                axios
                    .get(
                        `${newApi}/public/prescoringV2/landing/${broker}`,
                        //`${newApiPRE}/public/prescoringV2/landing/${broker}`,
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            // console.log("rta de getBroker", response)
                            resolve(response.data.data);
                        }
                    ).catch(error=>{
                        reject(error)
                    })
            })    
        },
        getOffice({state},data){
            return new Promise((resolve, reject)=>{
                axios
                    .get(
                        `${newApi}/public/prescoringV2/landing/${data.broker}/${data.office}`,
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            console.log(response);
                            resolve(response.data.data);
                        }
                    ).catch(error=>{
                        reject(error)
                    })
            })    
        },
        brokerInit({state},utm_params){
            this.commit('SET_INITIAL_DATA_LOADED', true);
            return;
        },
        getPrescoring({state},id){
            return new Promise((resolve,reject) => {
                axios
                    .get(
                        `${newApi}/public/prescoringV2/read_user/${id}/broker?action_staff=${state.action_staff}`,
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            // Save prescoring state data
                            this.commit('SET_PRESCORING_DATA', response.data.data.PreScoring);
                            this.commit('SET_INITIAL_DATA_LOADED', true);
                            
                            if(response.status){
                                resolve(response);
                            }else{
                                reject(response)
                            }
                        }
                    )
                    .catch(error => {
                        reject(error)
                    });
            })
        },
        retrieveVariable({state},payload){
            return new Promise((resolve,reject) => {
                axios
                    .get(
                        `${newApi}/public/variables?vars=${payload.type}`,
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            if(response.status){
                                switch (payload.type) {
                                    case 'ccaa':
                                        // Save ccaa in state data
                                        this.commit('SET_CCAA', response.data.data.ccaa);
                                        resolve(response.data.data.ccaa);
                                        break;
                                    case 'bancos':
                                        // Save banks in state data
                                        this.commit('SET_BANKS', response.data.data.bancos);
                                        resolve(response.data.data.ccaa);
                                        break;
                                    case 'tipo_contrato':
                                        // Save contract type in state data
                                        this.commit('SET_CONTRACT_TYPE', response.data.data.tipo_contrato);
                                        resolve(response.data.data.ccaa);
                                        break;
                                }
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
        saveProduct({state},payload){
            return new Promise((resolve,reject) => {
                let params = {};

                // Buscando
                let buscando = state.preScoring.Vivienda.buscando;
                switch (state.preScoring.Vivienda.tipo_vivienda) {
                    case 'ampliacion':
                    case 'autopromocion':
                    case 'mejorar':
                    case 'liquidez':
                    case 'extincion_condominio':
                        buscando = 'propia';
                        break;
                }

                // set default value for "vivienda_nueva"
                let viviendaNueva = state.preScoring.Vivienda.vivienda_nueva;
                switch (state.preScoring.Vivienda.vivienda_nueva) {
                    case 'ampliacion':
                    case 'mejorar':
                    case 'extincion_condominio':
                        viviendaNueva = 'no';
                        break;
                    case 'autopromocion':
                        viviendaNueva = 'si';
                        break;
                }

                // Common vars
                params['tipo_vivienda'] = state.preScoring.Vivienda.tipo_vivienda;
                params['direccion'] = state.preScoring.Vivienda.direccion;
                params['ccaa'] = state.preScoring.Vivienda.ccaa;
                params['localidad'] = state.preScoring.Vivienda.localidad;
                params['token_id'] = state.token_id;
                if(payload.lead_type){
                    params['lead_type'] = `L${payload.lead_type}`;
                }

                switch (state.preScoring.Vivienda.tipo_vivienda) {
                    // Producto habitual
                    case 'habitual':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['total_dinero_ahorrado'] = parseInt(state.preScoring.Titulares.total_dinero_ahorrado);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['date_keys'] = state.preScoring.Vivienda.date_keys;
                        break;
                    case '2avivienda':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['total_dinero_ahorrado'] = parseInt(state.preScoring.Titulares.total_dinero_ahorrado);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['date_keys'] = state.preScoring.Vivienda.date_keys;
                        params['avalista'] = state.preScoring.Vivienda.avalista;
                        params['avalista_tipo'] = (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_tipo : null;
                        params['avalista_hipoteca'] =  (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_hipoteca : null;
                        params['avalista_valor_vivienda'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_valor_vivienda) : null;
                        params['avalista_hipoteca_pendiente'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_hipoteca_pendiente) : null;
                        break;
                    case 'cambio_vivienda':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['total_dinero_ahorrado'] = parseInt(state.preScoring.Titulares.total_dinero_ahorrado);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['date_keys'] = state.preScoring.Vivienda.date_keys;
                        params['valor_vivienda_actual'] = state.preScoring.Vivienda.valor_vivienda_actual;
                        params['valor_hipoteca_vivienda_actual'] = state.preScoring.Vivienda.valor_hipoteca_vivienda_actual;
                        params['hipoteca_vivienda_actual'] = state.preScoring.Vivienda.hipoteca_vivienda_actual;
                        params['avalista'] = state.preScoring.Vivienda.avalista;
                        params['avalista_tipo'] = (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_tipo : null;
                        params['avalista_hipoteca'] =  (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_hipoteca : null;
                        params['avalista_valor_vivienda'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_valor_vivienda) : null;
                        params['avalista_hipoteca_pendiente'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_hipoteca_pendiente) : null;
                        break;
                    case 'compra_vivienda_100':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['total_dinero_ahorrado'] = parseInt(state.preScoring.Titulares.total_dinero_ahorrado);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['date_keys'] = state.preScoring.Vivienda.date_keys;
                        params['avalista'] = state.preScoring.Vivienda.avalista;
                        params['avalista_tipo'] = (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_tipo : null;
                        params['avalista_hipoteca'] =  (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_hipoteca : null;
                        params['avalista_valor_vivienda'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_valor_vivienda) : null;
                        params['avalista_hipoteca_pendiente'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_hipoteca_pendiente) : null;
                        break;
                    case 'liquidez':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['liquidez'] = parseInt(state.preScoring.Vivienda.liquidez);
                        params['otros_prestamos'] = parseInt(state.preScoring.Vivienda.otros_prestamos);
                        params['buscando'] = buscando;
                        break;
                    case 'autopromocion':
                        params['valor_terreno'] = parseInt(state.preScoring.Vivienda.valor_terreno);
                        params['valor_obra'] = parseInt(state.preScoring.Vivienda.valor_obra);
                        params['total_dinero_ahorrado'] = parseInt(state.preScoring.Titulares.total_dinero_ahorrado);
                        // params['date_keys'] = state.preScoring.Vivienda.date_keys;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['buscando'] = buscando;
                        params['avalista'] = state.preScoring.Vivienda.avalista;
                        params['avalista_tipo'] = (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_tipo : null;
                        params['avalista_hipoteca'] =  (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_hipoteca : null;
                        params['avalista_valor_vivienda'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_valor_vivienda) : null;
                        params['avalista_hipoteca_pendiente'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_hipoteca_pendiente) : null;
                        break;
                    case 'mejorar':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['capital_pendiente'] = parseInt(state.preScoring.Vivienda.capital_pendiente);
                        params['total_deudas'] = parseInt(state.preScoring.Vivienda.total_deudas);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        break;
                    case 'ampliacion':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['capital_pendiente'] = parseInt(state.preScoring.Vivienda.capital_pendiente);
                        params['liquidez'] = parseInt(state.preScoring.Vivienda.liquidez);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        break;
                    case 'extincion_condominio':
                        params['valor'] = parseInt(state.preScoring.Vivienda.valor);
                        params['capital_pendiente'] = parseInt(state.preScoring.Vivienda.capital_pendiente);
                        params['liquidez'] = parseInt(state.preScoring.Vivienda.liquidez);
                        params['buscando'] = buscando;
                        params['vivienda_nueva'] = viviendaNueva;
                        params['avalista'] = state.preScoring.Vivienda.avalista;
                        params['avalista_tipo'] = (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_tipo : null;
                        params['avalista_hipoteca'] =  (state.preScoring.Vivienda.avalista != 0) ? state.preScoring.Avales.avalista_hipoteca : null;
                        params['avalista_valor_vivienda'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_valor_vivienda) : null;
                        params['avalista_hipoteca_pendiente'] =  (state.preScoring.Vivienda.avalista != 0) ? parseInt(state.preScoring.Avales.avalista_hipoteca_pendiente) : null;
                        break;
                    default:
                        break;
                }
                if(payload.calculate === 1){
                    params['calculate']=1;
                }
                if(state.showIframe) params['action_staff'] = state.action_staff;
                axios
                    .put(
                        `${newApi}/public/prescoringV2/set/${payload.token}/broker`,
                        params,
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
        calculateScoring({state},id){
            return new Promise((resolve,reject) => {
                let params = {};
                params['numero_titulares'] = state.owners.length;
                params['edad_menor'] = moment().diff(moment.max(state.owners.map(owner=>moment(owner.nacimiento)))._i, 'years');
                params['total_neto_nominas'] = state.owners.map(owner=>owner.nomina).reduce((a, b) => parseInt(a) + parseInt(b), 0);
                params['numero_pagas'] = 12;
                params['total_gastos_mensuales'] = state.owners.map(owner=>owner.gastos).reduce((a, b) => parseInt(a) + parseInt(b), 0);
                params['oferta_type'] = (state.preScoring.Vivienda.oferta_type) ? state.preScoring.Vivienda.oferta_type : "unknown";
                params['oferta_years'] = (state.preScoring.Vivienda.oferta_years) ? `${state.preScoring.Vivienda.oferta_years}` : "30";
                // params['bancos_evaluando'] = "Ninguno";
                // params['contact_time_zone'] = "unknown";
                params['token_id'] = state.token_id;
                params['lead_type']="L3";
                params['calculate']=1;
                params['go_to_end'] = 1;
                if(state.showIframe) params['action_staff'] = state.action_staff;

                let ownersParams = {};
                            
                state.owners.forEach((owner, index)=>{
                    let _ownerindex = 'titular' + (index + 1);
                    let ret = {};
                    ret[_ownerindex + '_contrato'] = owner.contrato
                    ret[_ownerindex + '_antiguedad'] = owner.antiguedad
                    ret[_ownerindex + '_nomina'] = parseInt(owner.nomina)
                    ret[_ownerindex + '_gastos'] = parseInt(owner.gastos)
                    ret[_ownerindex + '_nacimiento'] = owner.nacimiento

                    ownersParams = {...ownersParams, ...ret};
                });
                        
                axios
                    .put(
                        `${newApi}/public/prescoringV2/set/${state.token_id}/broker`,
                        {...params, ...ownersParams},
                        {headers: {'X-WEB-KEY': xWebKey,
                                'X-DATA-ENV':xDataEnv
                            }}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
        sendPersoanlLoans({state}, personalLoan){
            return new Promise((resolve,reject) => {
                axios
                    .put(
                        `${newApi}/public/prescoringV2/set/${state.token_id}/broker`,
                        {token_id: state.token_id, prestamo_personal: personalLoan},
                        {headers: state.callsHeader}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
        callUrlFromResponse({state}, url){
            let params = {};
            if(state.showIframe) params['action_staff'] = state.action_staff;
            return new Promise((resolve,reject) => {
                axios
                    .put(
                        `${newApi}${url}`,
                        params,
                        {headers: {'X-WEB-KEY': xWebKey, 'X-DATA-ENV':xDataEnv }}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
        /* call old api*/
        /*callUrlFromResponse({state}, url){
            return new Promise((resolve,reject) => {
                axios
                    .post(
                        `${api}${url}`,
                        {},
                        {headers: {'X-WEB-KEY': 'DevelopmentProd' }}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },*/
        whatsappButton({state}){
            return new Promise((resolve,reject) => {
                axios
                    .get(
                        `${newApi}/public/prescoringV2/whatsapp_button`,
                        {headers: {'X-WEB-KEY': xWebKey}}
                    )
                    .then(
                        response => {
                            if(response.status){
                                resolve(response)
                            }else{
                                reject(response)
                            }
                        }
                    )
            })
        },
    },
    modules: {
    }
})
