import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import VueCurrencyFilter from 'vue-currency-filter'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'es',
	decimalLength: 0,
	autoDecimalMode: true,
	defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
})

Vue.use(VueCurrencyFilter,
  {
    symbol : '€',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true
  });


Vue.config.productionTip = false
Vue.use(VueCookies);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
