import { mapState } from 'vuex';
<template>
    <header>
        <div class="top_menu_bar_background" :style="this.backgroundImg?{'background-image':'url('+backgroundImg+')','background-size':'cover'} : {}"> 
            <div id="header-bar" class="d-flex justify-space-between">
                <div  class="d-flex">
                    <img class="img-logtop" :src="brokerImg" alt="Helloteca" width="120px">  <!-- :src="brokerData.portal_data.background_top_url"  -->
                </div>

                <div class="d-flex align-center">
                   <!--  <v-btn v-if="showWhatsapp" class="mx-2" fab dark color="green" href="https://wa.me/34639653706" target="_blank">
                        <v-icon dark>
                            mdi-whatsapp
                        </v-icon>
                    </v-btn> -->
                    <v-btn href="https://documentos.helloteca.com/#!/login" target="_blank" class="mx-2" fab dark color="primary">
                        <v-img width="56" :src="images.login_avatar"></v-img>
                    </v-btn> 
                </div>
            </div>
        </div>
        <nav id="tabs-menu">
           <ul>
                <li  :class="{ 'd-none' : ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') && activeStep !== 1}">
                    <button @click="goTo('view1')" :class="{active: activeStep === 1}">
                        <v-icon v-if="activeStep < 2" color="#FFF" class="mr-2">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-else color="#5DC49B" class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                        Datos de contacto
                    </button>
                </li>
                <li :class="{ 'd-none' : ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') && activeStep !== 2}">
                    <button @click="goTo('view2')" :disabled="isDisabled('view2')||stepWithError" :class="{active: activeStep === 2}">
                        <v-icon v-if="activeStep < 3" color="#FFF" class="mr-2">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-else color="#5DC49B" class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                        Datos de la operación
                    </button>
                </li>
                <li :class="{ 'd-none' : ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') && activeStep !== 3}">
                    <button @click="goTo('view3')" :disabled="isDisabled('view3')||stepWithError" :class="{active: activeStep === 3}">
                        <v-icon v-if="activeStep < 4" color="#FFF" class="mr-2">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-else color="#5DC49B" class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                        Datos económicos
                    </button>
                </li>
            </ul> 
        </nav>
    </header>
</template>
<script>
    import {mapState, mapActions} from 'vuex';
    export default {
        data(){
            return{
                showWhatsapp: null,
                images:{ 
                    login_avatar: require('@/assets/images/login-avatar.png')
                }
            }
        },
        mounted(){
            this.whatsappButton().then( response =>{
                this.showWhatsapp = response.data.data
            })
        },
        methods: {
            goTo(page){
                if (this.$route.path !== `/${page}`) this.$router.push(page);
            },
            isValidURL(string) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(string);
            },
            ...mapActions([
                'checkStepAccess',
                'whatsappButton'
            ]),
        },
        computed: {
            ...mapState([
                'loading',
                'activeStep',
                'preScoring',
                'accesibleViews',
                'stepWithError',
                'brokerData'
            ]),
            isDisabled(val){
                return val =>{
                    return !this.accesibleViews.includes(val)
                }
            },
            brokerImg(){
                try {
                    if(this.isValidURL(this.brokerData.portal_data.logo_top_url)){
                        return this.brokerData.portal_data.logo_top_url
                    } else{
                        return require(`@/assets/images/logo.svg`);
                    }
                } catch (error) {
                    return require(`@/assets/images/logo.svg`);
                }
            },
            backgroundImg(){
                try {
                    if(this.isValidURL(this.brokerData.portal_data.background_top_url)){
                        return this.brokerData.portal_data.background_top_url;
                    } else{
                        return false
                    }
                } catch (error) {
                    return false
                }
            }
        }        
    }
</script>
<style lang="scss" scoped>
    .top_menu_bar_background {
		background-image: URL('../assets/images/fondocabecera.jpg'); 
		background-repeat: no-repeat;
		background-position: center;
		background-color: #f7f7f7;
        @media (max-width: 700px) {
            background-image:none;
        }
	}
    #header-bar {
        height: 180px;
        max-width: 1150px;
        margin: 0 auto;
        text-align: right;
        padding: 0 20px;
        box-sizing: border-box;
        .img-logtop{
            margin-top: 1em;
            width: 230px;
            height: 150px;
            object-fit: contain;
        }
        .link {
            float: right;
            padding-top: 10px;
            padding-right: 10px;
            font-size: 21px;
            color: #C4C9CC;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            .theme {
                padding-left: 5px;
            }
        }
        span{
            &.login-phone{                
                font-size: 18px;
                font-weight: 700;
                color: #22CCD5;                
            }
            &.login-phone{        
                font-size: 18px;
                font-weight: 700;
                color: #22CCD5;
            }
            &.login-phone3 {                   
                font-size: 18px;
                font-weight: 700;
                color: #22CCD5;                   
                }
            
            &.login-phone2 {
                font-size: 11px!important;
                font-weight: 700;
                margin-top: -4px;
                color: #22CCD5;
            }
        }
        
        img {
            float: left;
            padding-top: 0px;
            margin-left: 5px;
            &.free-image {
                position: absolute;
                right: 3%;
                width: 70px;
                top: -6px;
                @media (max-width: 953px) {
                    display:none
                }
                @media (min-width: 954px) {
                    right: 10%;
                }
                @media (min-width: 1300px) {
                    right: 20%;
                }
            }
            &.login-image {
                position: absolute;
                right: 3%;
                width: 70px;
                top: -15px;
                @media (min-width: 954px) {
                    right: 2%;
                }
                @media (min-width: 1300px) {
                    right: 5%;
                }
            }

        }
    }
    #tabs-menu{
        background: #4D4D4D;
        justify-content: center !important;
        color: #FFF;
        box-shadow: none !important;
        height: 60px;
        display: flex;
        justify-content: center;
        ul{
            max-width: 1150px;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            list-style: none;
            justify-content: space-between;
            padding: 0;
            li{
                padding: 0;
                margin: 0;
                flex:1;
                button{
                    height: 100%;
                    font-size: 1rem;
                    font-weight: bold;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: background-color .2s;
                    background-color: #4D4D4D;
                    outline: none !important;
                    &.active{
                        transition: background-color .2s;
                        background: var(--helloteca-main) !important;
                    }
                    &:hover{
                        transition: background-color .2s;
                        background-color: rgba(#000, .1);
                    }
                }
            }
        }
    }
</style>
